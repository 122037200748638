import { useEffect } from "react"
export default function GoogleAds({ currentPath }) {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [currentPath])

  return (
    <div key={currentPath}>
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-9994520995876572"
        data-ad-slot="2828362481"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  )
}