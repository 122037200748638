import Head from 'next/head';
import Script from 'next/script';
import styles from '../styles/Home.module.css';
import Canvas from '../src/components/canvas/canvas';
import GoogleAds from '../src/components/googleAds/googleAds';

export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Y U basic, b*tch?</title>
        <meta name="description" content="Y U basic, b*tch? " />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Script
        id="Adsense-id"
        data-ad-client="ca-pub-9994520995876572"
        async="true"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
      <main className={styles.main}>
        <div className={styles.ad}>
          <GoogleAds currentPath="testAdsense" />
        </div>

        <h1>basic b*tches need to learn code.</h1>
        <Canvas />
      </main>
    </div>
  )
}
