import React, { useRef, useEffect } from 'react'
import style from './canvas.module.css'

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}


const Canvas = props => {
  const canvasRef = useRef(null)


  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let w;
    let h;
    let offset;
    let glitchInterval;

    const clear = () => {
      context.rect(0, 0, w, h);
      context.fill();
    };
    const randInt = function (a, b) {
      return ~~(Math.random() * (b - a) + a);
    };
    const glitchImg = (context) => {
      for (let i = 0; i < randInt(1, 13); i++) {
        let x = Math.random() * w;
        let y = Math.random() * h;
        let spliceWidth = w - x;
        let spliceHeight = randInt(5, h / 3);
        context.drawImage(canvas, 0, y, spliceWidth, spliceHeight, x, y, spliceWidth, spliceHeight);
        context.drawImage(canvas, spliceWidth, y, x, spliceHeight, 0, y, x, spliceHeight);
      }
    }
    async function magic() {

      const img = await createImage('assets/oie_Irm6hQR4grx9.png');
      canvas.width = w = window.innerWidth;
      offset = w * .1;
      canvas.height = h = ~~(200 * ((w - (offset * 2)) / img.width));
      glitchInterval = setInterval(function () {
        clear();
        context.drawImage(img, 0, 110, img.width, 200, offset, 0, w - (offset * 2), h);
        setTimeout(glitchImg(context), randInt(250, 1000));
      }, 500);

      glitchImg(context)
    }

    magic();
    return () => {
      clear()
    }
  }, [])

  return <canvas className={style.Canvas} ref={canvasRef} {...props} />
}


export default Canvas